import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';
import Layout from '../templates/Layout';

const Vacancy = ({ data: { vacancy } }) => {
  return (
    <Layout siteData={vacancy.datoCmsSetting}>
      {/* <Img fluid={vacancy.photo.fluid} className="vacancy__banner tablet" /> */}
      <article className="sheet">
        <h1 className="sheet__title">{vacancy.title}</h1>
        <Img fluid={vacancy.photo.fluid} className="vacancy__banner desktop" />
        <div
          className="sheet__body"
          dangerouslySetInnerHTML={{
            __html: vacancy.descriptionNode.childMarkdownRemark.html,
          }}
        />
      </article>
    </Layout>
  );
};

export const query = graphql`
  query VacancyQuery($locale: String!) {
    vacancy: datoCmsVacancyPage(locale: { eq: $locale }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      descriptionNode {
        childMarkdownRemark {
          html
        }
      }
      photo {
        fluid(maxWidth: 1200, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsSizes
        }
      }
    }
  }
`;

export default Vacancy;
